import { createSlice } from '@reduxjs/toolkit'

import { FetchSensorData, OfficeDataState, TempProps, HumProps } from '../../states/utilState'
import { fetchOfficeData } from './asyncAction'

export const initialState: OfficeDataState = {
    sensorDatas: [],
    weather: 0,
    timeZone: 0,
    isDataexist: false
}

const officeDataSlice = createSlice({
    name: 'officeData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOfficeData.fulfilled, (state, action) => {
            // console.log("call action");
            if (action.payload !== undefined) {
                // console.log("ineed");
                state.sensorDatas = action.payload.sensorInfo.map((data)=>{
                    return {
                        placeName: data.placeName,
                        temp: getTempProp(data),
                        hum: getHumProp(data),
                        comfort: data.comfort,
                        volume: data.volume,
                        light: data.light,
                        co2: data.co2,
                        evaluation: getEvaluation(data),
                    }
                })
                state.weather = action.payload.weather
                state.timeZone = action.payload.timeZone
                state.isDataexist = true
            }
        })
    }
})

type TempPropScore = {
    (data: FetchSensorData): TempProps;
}

const getTempProp: TempPropScore = (data: FetchSensorData) => {
    let prop: TempProps = {
        temp: Math.round(data.temp),
        level: 1,
        msg: "普通"
    };

    if (Math.round(data.temp) <= 17) {
        prop.level = 0;
        prop.msg = "寒い";
    } else if (28 <= Math.round(data.temp)) {
        prop.level = 2;
        prop.msg = "暑い";
    } 

    return prop;
}

type HumPropScore = {
    (data: FetchSensorData): HumProps;
}

const getHumProp: HumPropScore = (data: FetchSensorData) => {
    let prop: HumProps = {
        hum: data.hum,
        level: 1,
        msg: "普通"
    };

    if (data.hum < 40) {
        prop.level = 0;
        prop.msg = "乾燥";
    } else if (70 < data.hum) {
        prop.level = 2;
        prop.msg = "多湿";
    } 

    return prop;
}

type EvaluationScore = {
    (data: FetchSensorData): number;
}

const getEvaluation: EvaluationScore = (data: FetchSensorData) => {
    let score: number = 0;
    let evaluation: number = 0;

    // 温度（快適だと1点、寒すぎ・暑すぎは-1点）
    if (22 <= Math.round(data.temp) && Math.round(data.temp) <= 24) {
        score++;
    } else if (Math.round(data.temp) <= 17 || 28 <= Math.round(data.temp)) {
        score--;
    } 

    // 温度（快適だと1点、乾燥・多湿は-1点）
    if (45 <= data.hum && data.hum <= 60) {
        score++;
    } else if (data.hum < 40 || 70 < data.hum) {
        score--;
    } 

    // CO2（700ppm以下:2点、1000ppm以上:-2点）
    if (data.co2.ppm <= 700) {
        score += 2;
    } else if (data.co2.ppm >= 1000) {
        score -= 2;
    }

    // 明るさ（眩しいと-1点）
    if (data.light.lx >= 1000) {
        score--;
    }

    if (score >= 1) {
        evaluation = 2; // 快適
    } else if (score >= -2) {
        evaluation = 1; // 普通
    } else {
        evaluation = 0; // 要改善
    }

    return evaluation;
}

// eslint-disable-next-line
export const {} = officeDataSlice.actions

export default officeDataSlice.reducer

