import {
    DisplayDataBlockWrapper, DisplayDataWrapper, SensorIconWrapper, SensorDataWrapper
} from "./styledComponents"
import { TempIcon, HumIcon, CO2Icon, LightIcon, SoundIcon } from '../../Atoms/Image'
import { SensorData } from "../../../states/utilState"


const DisplayDataBlock:React.FC<{sensorData: SensorData}> = (props) => {
    let strVol: string;
    let strLight: string;
    let strCO2: string;

    if (props.sensorData.volume.db < 0) {
        strVol = "-";
    } else {
        strVol = props.sensorData.volume.db.toString();
    }

    if (props.sensorData.light.lx < 0) {
        strLight = "-";
    } else {
        strLight = props.sensorData.light.lx.toString();
    }

    if (props.sensorData.co2.ppm < 0) {
        strCO2 = "-";
    } else {
        strCO2 = props.sensorData.co2.ppm.toString();
    }

    return (
        <DisplayDataBlockWrapper>

            <DisplayDataWrapper>
                <SensorIconWrapper><TempIcon level={props.sensorData.temp.level}></TempIcon></SensorIconWrapper>
                <SensorDataWrapper>{props.sensorData.temp.temp}<span className="unit">℃</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper>
                <SensorIconWrapper><HumIcon level={props.sensorData.hum.level}></HumIcon></SensorIconWrapper>
                <SensorDataWrapper>{props.sensorData.hum.hum}<span className="unit">%</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper>
                <SensorIconWrapper><SoundIcon level={props.sensorData.volume.level}></SoundIcon></SensorIconWrapper>
                <SensorDataWrapper>{strVol}<span className="unit">dB</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper>
                <SensorIconWrapper><LightIcon level={props.sensorData.light.level}></LightIcon></SensorIconWrapper>
                <SensorDataWrapper>{strLight}<span className="unit">lx</span></SensorDataWrapper>
            </DisplayDataWrapper>

            <DisplayDataWrapper>
                <SensorIconWrapper><CO2Icon level={props.sensorData.co2.level}></CO2Icon></SensorIconWrapper>
                <SensorDataWrapper>{strCO2}<span className="unit">ppm</span></SensorDataWrapper>
            </DisplayDataWrapper>

        </DisplayDataBlockWrapper>
    )
}

export default DisplayDataBlock

