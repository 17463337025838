import React, { useEffect, useState } from 'react'

import {
    Wrapper, HeaderWrapper, LogoAreaWrapper, WeatherWrapper, EnvTitleWrapper
} from './styledComponents'
import SensorContainer from '../Organisms/ContainerBlock'
import DateTimeBlock from '../Organisms/DateTimeBlock'

import { SensorData, FetchMinMaxTempData } from '../../states/utilState'
import { useOfficeDataState } from '../../reducer/officeData/selectors'
import { useDispatch } from 'react-redux'
import { fetchOfficeData } from '../../reducer/officeData/asyncAction'

import { useMinMaxTempDataState } from '../../reducer/minMaxData/selectors'
import { fetchMinMaxTempData } from '../../reducer/minMaxData/asyncAction'

const MainTemplate: React.FC = () => {

    const officeDataState = useOfficeDataState().officeData
    /* eslint-disable */
    const [officeData, setofficeData] = useState<SensorData[]>()
    const [placeAmount, setPlaceAmount] = useState(0)
    /* eslint-enable */
    const dispatch = useDispatch()
    var timer: NodeJS.Timeout
    var timer2: NodeJS.Timeout

    const polling = () => {
        timer = setTimeout(()=>{
            dispatch(fetchOfficeData())
            polling()
            setofficeData([...officeDataState.sensorDatas])
            setPlaceAmount(officeDataState.sensorDatas.length)
        }, 60 * 1000)
    }
    useEffect(()=>{
        dispatch(fetchOfficeData())
        polling()
        return ()=> clearTimeout(timer)
    },[setofficeData,officeDataState.isDataexist])  // eslint-disable-line

    const minMaxDataState = useMinMaxTempDataState().minMaxData
    /* eslint-disable */
    const [minMaxData, setMinMaxData] = useState<FetchMinMaxTempData>()
    /* eslint-enable */
    
    const minMaxPolling = () => {
        timer2 = setTimeout(()=>{
            dispatch(fetchMinMaxTempData())
            minMaxPolling()
            setMinMaxData(minMaxDataState.temp)
        }, 60 * 60 * 1000)
    }
    useEffect(()=>{
        dispatch(fetchMinMaxTempData())
        minMaxPolling()
        return ()=> clearTimeout(timer2)
    },[setMinMaxData,minMaxDataState.isDataexist])  // eslint-disable-line

    return (
        <Wrapper >
            <HeaderWrapper>
                <LogoAreaWrapper></LogoAreaWrapper>
                <WeatherWrapper>{minMaxDataState.temp?.addr}　予想最高気温：{minMaxDataState.temp?.max}℃ 予想最低気温：{minMaxDataState.temp?.min}℃</WeatherWrapper>
            </HeaderWrapper>

            <EnvTitleWrapper>現在の環境</EnvTitleWrapper>
            <DateTimeBlock></DateTimeBlock>

            <SensorContainer sensorData={officeDataState.sensorDatas}></SensorContainer>
        </Wrapper>
    )
}

export default MainTemplate