import styled from "styled-components"

export const DisplayDataBlockWrapper = styled.div`
position: relative;
width: 100%;
line-height: 1.1;
flex-direction: column;
flex-wrap: wrap;
justify-content: center;
font-size: 3vh;
text-align: center;
display: flex;
align-items: center;
font-weight: 600;
color: #666;
top: 2vh;
height: 35vh;
`
export const ContainerWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 33vh;
  left: 8vw;
  width: 84vw;
  height: 61vh;
  display: flex;
  padding: 0;
  justify-content: space-between;
`

export const DisplayDataWrapper = styled.div`
position: relative;
width: 100%;
line-height: 1.1;
flex-direction: column;
flex-wrap: wrap;
font-size: 3vh;
text-align: center;
display: flex;
align-items: center;
font-weight: 600;
color: #666;
top: 0;
height: 7vh;
display: flex;
justify-content: space-between;
`
export const SensorIconWrapper = styled.div`
position: relative;
line-height: 1.1;
flex-direction: column;
flex-wrap: wrap;
font-size: 3vh;
text-align: center;
display: flex;
align-items: center;
font-weight: 600;
color: #666;
top: 0;
height: 7vh;
width: 40%;
display: flex;
justify-content: center;
& > img {
    height: 4.5vh;
}
`

export const SensorDataWrapper = styled.div`
line-height: 1.1;
display: flex;
align-items: center;
font-weight: 600;
color: #666;
display: flex;
justify-content: start;
height: 7vh;
width: 60%;
text-align: left;
align-items: center;
font-size: 3.5vh;


& > span.unit {
    padding-top: 0.7vh;
    padding-left: 0.3vw;
    font-size: 2.8vh;
    font-weight: 500;
}
`

