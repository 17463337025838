import styled from "styled-components"

export const EvaluationWrapper = styled.div`
position: relative;
width: 100%;
line-height: 1.1;
flex-direction: column;
flex-wrap: wrap;
justify-content: center;
font-size: 3vh;
text-align: center;
display: flex;
align-items: center;
font-weight: 600;
color: #666;
top: 0;
height: 14vh;
> img {
  width: 12vh;
  height: 12vh;
}
`
