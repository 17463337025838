import styled from "styled-components"

export const SensorNameWrapper = styled.div`
  position: relative;
  top: 0;
  height: 10vh;
  width: 100%;
  line-height: 1.1;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: #666;
  font-size: 3.2vh;
  font-weight: 500;
`
