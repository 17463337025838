import styled from "styled-components"

export const ContainerWrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 33vh;
  left: 8vw;
  width: 84vw;
  height: 61vh;
  display: flex;
  padding: 0;
  justify-content: space-between;
`
