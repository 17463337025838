import styled from 'styled-components';
export const Wrapper = styled.div`
  box-sizing: border-box;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'M PLUS Rounded 1c', helvetica;
`

export const HeaderWrapper = styled.div`
  top: 0;
  left: 0;
  width: 100vw;
  height: 10vh;
  display: flex;
  justify-content: space-between;
`

export const LogoAreaWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 12.5vw;
  height: 10vh;
`

export const WeatherWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 3vh;
  padding: 4vh 2vw 1.5vh;
  width: 50vw;
  margin: 0;
  color: navy;
  text-align: right;
  font-size: 3vh;
  font-weight: 500;
  line-height: 1;
`

export const EnvTitleWrapper = styled.div`
  position: absolute;
  top: 17vh;
  left: 0;
  width: 100vw;
  height: 7vh;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #666;
  font-size: 7vh;
  font-weight: 500;
  line-height: 1;
`
