import { Store, combineReducers } from 'redux'
import officeDataSlice, {initialState as officeDataInitialState } from "./officeData/slice"
import minMaxDataSlice, {initialMinMaxState as minMaxDataInitialState } from "./minMaxData/slice"

export const rootReducer = combineReducers({
    officeData: officeDataSlice,
    minMaxData: minMaxDataSlice,
})

export const preloadedState = () => {
    return {
        officeData: officeDataInitialState,
        minMaxData: minMaxDataInitialState,
    }
}

export type StoreState = ReturnType<typeof preloadedState>

export type ReduxStore = Store<StoreState>