import styled from "styled-components"

export const SensorBlockWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  &:not(:first-child) {
    border-left:#666 1px solid;
  }
`

