import {
    ContainerWrapper
} from "./styledComponents"

import {
    SensorBlockWrapper
} from "../SensorBlock/styledComponents"

import SensorName from "../../Molecules/SensorName/index"
import Evaluation from "../../Molecules/Evaluation/index"
import DisplayDataBlock from "../../Molecules/DisplayData/index"

import { SensorData } from "../../../states/utilState"

const SensorContainer:React.FC<{sensorData: SensorData[]}> = (props) => {
    return (
        <ContainerWrapper>
            {makeSensorBlockComponent(props.sensorData)}
        </ContainerWrapper>
    )
}

export default SensorContainer

const makeSensorBlockComponent = (datas: SensorData[]) => {
    return datas.map((data, index)=> {
        if (index < 5) {
            return (
                <SensorBlockWrapper>
                    <SensorName placeName={data.placeName}></SensorName>
                    <Evaluation level={data.evaluation}></Evaluation>
                    <DisplayDataBlock sensorData={data}></DisplayDataBlock>
                </SensorBlockWrapper>
            )
        } else {
            return (<></>);
        }
    })
}
