// アイコンlight
import CO2GreenLight from "../image/icon/CO2_green.svg"
import CO2YellowLight from "../image/icon/CO2_yellow.svg"
import CO2OrangeLight from "../image/icon/CO2_orange.svg"
import soundVLowLight from "../image/icon/volume_none.svg"
import soundLowLight from "../image/icon/volume_none.svg"
import soundNormalLight from "../image/icon/volume_down.svg"
import soundHighLight from "../image/icon/volume_up.svg"
import soundVHighLight from "../image/icon/volume_up.svg"
import lightDarkLight from "../image/icon/light_emp.svg"
import lightNormalLight from "../image/icon/light_half.svg"
import lightHighLight from "../image/icon/light_full.svg"
import humLowLight from "../image/icon/waterdrop_emp.svg"
import humMidLight from "../image/icon/waterdrop_half.svg"
import humHighLight from "../image/icon/waterdrop_full.svg"
import tempLowLight from "../image/icon/thermostat_blue.svg"
import tempMidLight from "../image/icon/thermostat_green.svg"
import tempHighLight from "../image/icon/thermostat_orange.svg"

// 評価
import evaluationPoor from "../image/status/bad.svg"
import evaluationNormal from "../image/status/normal.svg"
import evaluationGood from "../image/status/good.svg"

export const EvaluationIconName : string[] = [
    evaluationPoor,
    evaluationNormal,
    evaluationGood
];

export const TempIconName : string[] = [
    tempLowLight,
    tempMidLight,
    tempHighLight
];

export const HumIconName : string[] = [
    humLowLight,
    humMidLight,
    humHighLight
];

export const CO2IconName : string[] = [
    CO2GreenLight,
    CO2YellowLight,
    CO2OrangeLight
];

export const LightIconName : string[] = [
    lightDarkLight,
    lightNormalLight,
    lightHighLight
];

export const SoundIconName : string[] = [
    soundVLowLight,
    soundLowLight,
    soundNormalLight,
    soundHighLight,
    soundVHighLight
];


export const EvaluationIconAlt : string[] = [
    "要改善",
    "普通",
    "良好"
];

export const TempIconAlt : string[] = [
    "低温",
    "適温",
    "高温"
];

export const HumIconAlt : string[] = [
    "乾燥",
    "良好",
    "高湿"
];

export const CO2IconAlt : string[] = [
    "良好",
    "注意",
    "高い"
];

export const LightIconAlt : string[] = [
    "暗い",
    "良好",
    "眩しい"
];

export const SoundIconAlt : string[] = [
    "非常に静か",
    "静か",
    "普通",
    "ややうるさい",
    "うるさい"
];


export const EvaluationIconImage:React.FC<{level: number}> = (props) => {
    return (
        <img src={EvaluationIconName[props.level]} alt={EvaluationIconAlt[props.level]}/>
    )
}

export const TempIconImage:React.FC<{level: number}> = (props) => {
    return (
        <img src={TempIconName[props.level]} alt={TempIconAlt[props.level]} />
    )
}

export const HumIconImage:React.FC<{level: number}> = (props) => {
    return (
        <img src={HumIconName[props.level]} alt={HumIconAlt[props.level]} />
    )
}

export const CO2IconImage:React.FC<{level: number}> = (props) => {
    return (
        <img src={CO2IconName[props.level]} alt={CO2IconAlt[props.level]} />
    )
}

export const LightIconImage:React.FC<{level: number}> = (props) => {
    const light: number = props.level + 1;  // -1〜1の範囲で来るため
    return (
        <img src={LightIconName[light]} alt={LightIconAlt[light]} />
    )
}

export const SoundIconImage:React.FC<{level: number}> = (props) => {
    const sound: number = props.level + 2;  // -2〜2の範囲で来るため
    return (
        <img src={SoundIconName[sound]} alt={SoundIconAlt[sound]} />
    )
}
