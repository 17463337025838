import styled from "styled-components"

export const DateTimeWrapper = styled.div`
  position: absolute;
  top: 25.5vh;
  left: 0;
  width: 100vw;
  padding: 0;
  font-size: 3vh;
  color: #666;
  margin: 0;
  text-align: center;
  font-weight: 700;
  line-height: 1;
  & > span.dow {
    font-size: 1.5vh;
  }
`
